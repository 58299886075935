<template>
  <div class="discuss_wrap">
    <TopCard ref="TopCardRef"
             text="线上答疑"
             :showBack="true" />
    <div class="subject_card_wrap">
      <div class="card"
           @click="checkBoard(item)"
           :style="{'background':boardBackg[item.board_id]?boardBackg[item.board_id].background:'','box-shadow':board_id==item.board_id?'inset #c9bfbf8c -6rem -6rem 8rem':''}"
           v-for="item in boardList"
           :key="item.board_id">
        <p>
          {{item.board_name}}
        </p>
        <img :src="boardBackg[item.board_id]?boardBackg[item.board_id].url:''">
      </div>
    </div>
    <div class="type_wrap">
      <div class="post_type">
        <div :class="{'item':true,'isActive':item.value == type}"
             @click="checkType(item)"
             v-for="item in discuss_post_type"
             :key="item.value">
          {{item.label}}
        </div>
      </div>
      <div class="search">
        <div class="btn"
             @click="fatie">
          发帖
        </div>
        <img src="@/assets/icon-搜索@2x.png"
             @click="showDialog"
             alt="">
      </div>
    </div>
    <div class="list">
      <div class="discuss_item"
           v-for="(item,index) in list"
           @click="toPage(item)"
           :key="index">
        <div class="title">
          <div>
            {{item.discuss_title}}
          </div>
          <div>
            {{item.board_name}}
          </div>
        </div>
        <div class="content">
          {{item.discuss_content}}
        </div>
        <div class="attr">
          <div class="left">
            <div>
              <img src="@/assets/icon-点赞@2x.png"
                   alt="">
              {{item.discuss_upvote_times}}
            </div>
            <div>
              <img src="@/assets/icon-评论@2x.png"
                   alt="">
              {{item.discuss_re_times}}
            </div>
          </div>
          <div class="right">
            {{item.discuss_time}}
          </div>
        </div>
      </div>
    </div>
    <Form ref="FormRef" />
    <Reply ref="ReplyRef" />
  </div>
</template>

<script>
import { getBoard, getBoardList } from '@/api/discuss.js'
import TopCard from '@/views/components/top_card/index.vue'
import Form from './components/form.vue'
import Reply from './components/reply.vue'

export default {
  data () {
    return {
      type: 0,
      board_id: '',
      keyword: '',
      list: [],
      boardBackg: {
        1: {
          background: '#FDE7E7',
          url: require(`@/assets/discuss/icon-语文@2x.png`)
        },
        2: {
          background: '#FDF0E7',
          url: require(`@/assets/discuss/icon-数学@2x.png`)
        },
        3: {
          background: '#ECE8FF',
          url: require(`@/assets/discuss/icon-英语@2x.png`)
        },
        4: {
          background: '#FFF5DD',
          url: require(`@/assets/discuss/icon-物理@2x.png`)

        },
        5: {
          background: '#E7FDF1',
          url: require(`@/assets/discuss/icon-化学@2x.png`)

        },
        6: {
          background: '#EAF9FF',
          url: require(`@/assets/discuss/icon-生物@2x.png`)
        },
        // 地理
        7: {
          background: '#EAF9FF',
          url: require(`@/assets/discuss/icon-生物@2x.png`)
        },
        // 历史
        8: {
          background: '#FFF5DD',
          url: require(`@/assets/discuss/icon-物理@2x.png`)
        },
        // 政治
        9: {
          background: '#E7FDF1',
          url: require(`@/assets/discuss/icon-化学@2x.png`)
        },
        10: {
          background: '#FFF5DD',
          url: require(`@/assets/discuss/icon-服务区@2x.png`)
        }
      },
      boardList: [
        { board_name: '语文', board_id: 1 },
        { board_name: '数学', board_id: 2 },
        { board_name: '英语', board_id: 3 },
        { board_name: '物理', board_id: 4 },
        { board_name: '化学', board_id: 5 },
        { board_name: '生物', board_id: 6 },
        { board_name: '服务区', board_id: 11 },
      ],
      discuss_post_type: [
        { label: '全部帖子', value: 0 },
        { label: '我的帖子', value: 1 },
        { label: '我的回帖', value: 2 },
        { label: '我的点赞', value: 3 },
      ]
    }
  },
  components: {
    TopCard, Form, Reply
  },
  mounted () {
    this.initList()
    // this.$nextTick(() => {
    //   this.$refs.FormRef.dialogVisible = true
    // })

  },
  methods: {
    toPage (item) {
      this.$router.push('/discuss_info?discuss_id=' + item.discuss_id)
    },
    async initList () {
      const { data } = await getBoard()
      // console.log(data)
      this.boardList = data
      console.log('sssssss',JSON.parse(window.localStorage.getItem('board_item')))
      if (JSON.parse(window.localStorage.getItem('board_item'))) {
        this.checkBoard(JSON.parse(window.localStorage.getItem('board_item')))
      } else {
        this.checkBoard(data[0])
      }

    },
    async checkBoard (item = null) {
      window.localStorage.setItem('board_item', JSON.stringify(item))
      if (item) {
        this.board_id = item.board_id
        this.keyword = ''
      }
      let params = {
        discuss_board: this.board_id,
        discuss_post_type: this.type,
        search: this.keyword
      }
      const { data } = await getBoardList(params)
      this.list = data.volist
    },
    checkType (item) {
      this.type = item.value
      this.keyword = ''
      this.checkBoard()
    },
    fatie () {
      let form = {
        board_id: '',
        discuss_title: '',
        discuss_content: '',
        question_id: '',
      }
      this.$refs.FormRef.dialogVisible = true
      this.$refs.FormRef.form = form
      // 这里不知道为什么 dialog的z-index比loading高
      setTimeout(() => {
        document.getElementsByClassName('v-modal')[0].style['z-index'] = 1998
      }, 100);
    },
    showDialog () {
      this.$refs.ReplyRef.keyword = this.keyword
      this.$refs.ReplyRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.discuss_wrap {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .subject_card_wrap {
    display: flex;
    margin-bottom: 20rem;
    overflow-x: auto;
    padding-bottom: 20rem;
    // overflow-x: auto;
    .card {
      flex-shrink: 0;
      width: 11.5%;
      height: 120rem;
      border-radius: 10rem;
      margin-left: 2.6%;
      font-size: 24rem;
      font-weight: bold;
      color: #333333;
      position: relative;
      cursor: pointer;
      p {
        margin: 20rem 0 0 20rem;
      }
      img {
        width: 32%;
        height: 54%;
        position: absolute;
        right: 10%;
        top: 36rem;
      }
      &:nth-child(1) {
        margin-left: 2.08%;
      }
    }
  }
  .type_wrap {
    height: 80rem;
    border-top: 1rem solid #e5e5e5;
    box-shadow: inset 0rem -1rem 0rem 0rem rgba(229, 229, 229, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .post_type {
      width: 40%;
      display: flex;
      justify-content: space-between;
      .item {
        width: 100rem;
        height: 80rem;
        line-height: 80rem;
        font-size: 24rem;
        font-weight: 500;
        color: #333333;
        cursor: pointer;
      }
    }
    .search {
      position: absolute;
      right: 40rem;
      width: 250rem;
      display: flex;
      align-items: center;
      .btn {
        width: 140rem;
        height: 54rem;
        background: #2196f3;
        border-radius: 35rem;
        font-size: 24rem;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      img {
        width: 26rem;
        height: 26rem;
        margin-left: 40rem;
        cursor: pointer;
      }
    }
  }
  .list {
    height: calc(100vh - 330rem);
    overflow-y: auto;
    background: #f6f6f6;
    .discuss_item {
      height: 184rem;
      background: #ffffff;
      border-radius: 10rem;
      margin: 30rem;
      overflow: hidden;
      cursor: pointer;
      .title {
        display: flex;
        justify-content: space-between;
        margin: 20rem;
        div {
          &:nth-child(1) {
            font-size: 24rem;
            font-weight: bold;
            color: #333333;
          }
          &:nth-child(2) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90rem;
            height: 44rem;
            background: #ebf3ff;
            border-radius: 5rem;
            font-size: 22rem;
            font-weight: bold;
            color: #2196f3;
          }
        }
      }
      .content {
        font-size: 24rem;
        font-weight: 500;
        color: #333333;
        margin-left: 20rem;
      }
      .attr {
        margin: 20rem;
        display: flex;
        display: flex;
        justify-content: space-between;
        .left {
          color: #666666;
          font-size: 28rem;
          font-weight: 500;
          display: flex;
          div {
            display: flex;
            align-items: center;
            &:nth-child(2) {
              margin-left: 40rem;
            }
          }

          img {
            width: 24rem;
            height: 24rem;
            margin-right: 14rem;
          }
        }
        .right {
          font-size: 20rem;
          font-weight: 500;
          color: #999999;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .pagintion {
    height: 50rem;
  }
  .isActive {
    color: #2196f3;
    font-weight: bold;
    border-bottom: 4rem solid #2196f3;
  }
  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  /* 滚动条上的按钮 (上下箭头). */
  ::-webkit-scrollbar-button {
    display: none;
  }
  /* 滚动条上的滚动滑块. */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(202, 202, 210, 0.8);
    border-radius: 50px;
    cursor: pointer;
  }
  /* 滚动条没有滑块的轨道部分 */
  ::-webkit-scrollbar-track-piece {
    border-radius: 20px;
  }
  /* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

::v-deep .el-dialog__wrapper {
  z-index: 1999 !important;
}
// .v-modal {
//   z-index: 1990 !important;
// }
</style>