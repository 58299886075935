<template>
  <div>
    <el-dialog title="搜索"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400rem"
               :before-close="handleClose">
      <el-form ref="formRef"
               label-width="60rem">
        <el-row :gutter="10">
          <el-col :span="23">
            <el-form-item label="内容:"
                          prop="discuss_content">
              <el-input placeholder="请输入搜索内容"
                        style="width:100%"
                        v-model="keywords" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="btn_warp">
        <div @click="handleClose">退出</div>
        <div @click="save">查询</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data () {
    return {
      dialogVisible: false,
      keywords: ''

    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    save () {
      this.$parent.keyword = this.keywords
      this.$parent.checkBoard()
      this.handleClose()
    },
  }
}
</script>

<style lang="scss" scoped>
.btn_warp {
  border-top: 1rem solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60rem;
  div {
    flex: 1;
    // height: 70rem;
    font-weight: bold;
    // font-size: 24rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(2) {
      color: #2196f3;
      border-left: 1rem solid #f4f4f4;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__header {
  // font-size: 24rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
::v-deep .el-dialog {
  border-radius: 10rem;
}

.images {
  display: flex;
}

::v-deep .el-form-item__label {
  // font-size: 24rem;
}
::v-deep .el-textarea__inner {
  // font-size: 24rem;
}
</style>